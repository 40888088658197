import React from 'react';
import { useTheme } from '@mui/material/styles';
//import SvgIcon from '@mui/material/SvgIcon';

export default ({...other}) => {
  const theme = useTheme();
  const defaultColor = React.useMemo(() => ({backgroundColor: undefined, color: theme.palette.primary.main}));
  const [color, setColor] = React.useState(defaultColor);
  const handleMouseOver = React.useCallback(() => {
    setColor({backgroundColor: theme.palette.primary.main, color: theme.palette.common.black})
  }, [theme])

  const handleMouseOut = React.useCallback(() => {
    setColor(defaultColor)
  }, [defaultColor])

  return (
    <svg {...other} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="28.5" cy="28" rx="28.5" ry="28" fill={`${color.backgroundColor}`}/>
      <circle cx="28" cy="28" r="27" stroke={`${theme.palette.primary.main}`} strokeWidth="2"/>
      <path d="M37.6586 32.0615C37.6586 34.6543 35.4653 36.7561 32.7598 36.7561H23.2408C20.5353 36.7561 18.3414 34.6543 18.3414 32.0615V22.9385C18.3414 20.3457 20.5353 18.2439 23.2408 18.2439H32.7598C35.4653 18.2439 37.6586 20.3457 37.6586 22.9385V32.0615ZM40 22.8357C40 19.0606 36.8063 16 32.8665 16H23.1335C19.1937 16 16 19.0606 16 22.8357V32.1643C16 35.9394 19.1937 39 23.1335 39H32.8665C36.8063 39 40 35.9394 40 32.1643V22.8357ZM31.9094 27.3383C31.9094 25.3589 30.2286 23.7481 28.1631 23.7481C26.0977 23.7481 24.4168 25.3589 24.4168 27.3383C24.4168 29.3177 26.0977 30.9285 28.1631 30.9285C30.2286 30.9285 31.9094 29.3177 31.9094 27.3383M34.0115 27.3383C34.0115 30.4281 31.3884 32.9424 28.1631 32.9424C24.9384 32.9424 22.3154 30.4281 22.3154 27.3383C22.3154 24.248 24.9384 21.7337 28.1631 21.7337C31.3884 21.7337 34.0115 24.248 34.0115 27.3383M35.7451 21.3568C35.7451 22.0939 35.1217 22.6912 34.3526 22.6912C33.5841 22.6912 32.9608 22.0939 32.9608 21.3568C32.9608 20.6197 33.5841 20.0229 34.3526 20.0229C35.1217 20.0229 35.7451 20.6197 35.7451 21.3568" fill={`${color.color}`}/>
    </svg>
  )
};
