import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from './icons/SearchIcon';
import GlossaryTabPanel from './GlossaryTabPanel';
import './GlossarySearchPanel.css'

function GlossarySearchPanel(props) {
  const { value, index, onChange, defaultValue, ...other } = props;

  return (
    <GlossaryTabPanel value={value} index={index} id={'glossary-search'}>
      <TextField
        InputProps={{
          startAdornment:
            (<InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>),
          sx: {p: 0},
          variant: "custom1",
        }}
        inputProps={{
          type:"search",
          //sx: { color: 'purple'}
        }}
        defaultValue={defaultValue}
        sx={{width: '100%'}}
        placeholder="What are you looking for?"
        name="search"
        onChange={onChange}
      />
    </GlossaryTabPanel>
  );
}

GlossarySearchPanel.propTypes = {
  onChange: PropTypes.func,
};

export default GlossarySearchPanel;
