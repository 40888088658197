import React from 'react';

export default () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 18H16V22H0V18Z" fill="#14E2A7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 0V14H6L6 0H10Z" fill="#14E2A7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.00007 15.9999L7.21216e-05 8.99992L1.97559 6.74219L9.97559 13.7422L8.00007 15.9999Z" fill="#14E2A7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.99993 15.9999L15.9999 8.99992L14.0244 6.74219L6.02441 13.7422L7.99993 15.9999Z" fill="#14E2A7"/>
    </svg>
  )
}
