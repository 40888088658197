import React from 'react';

export default ({checked}) => {
  if(checked){
    return (
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.25" width="10" height="10" fill="#14E2A7"/>
      </svg>
    )    
  }else{
    return (
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="9" height="9" stroke="#14E2A7"/>
      </svg>
    )
  }
}
