const port = process.env.REACT_APP_API_PORT == null ? 1337 : process.env.REACT_APP_API_PORT;
const host = process.env.REACT_APP_API_HOST || 'localhost';
const protocol = process.env.REACT_APP_API_PROTOCOL || 'http';

const baseUrl = `${protocol}://${host}` + (port == null ? '' : `:${port}`);

// console.log('base url', baseUrl, 'port: ', port);

export const getApiBaseUrl = () => {
  return baseUrl;
}


export const debounce = (func, wait) => {
  var timeout;
  return function() {
    //console.log('call debounce:'+arguments[0])
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
