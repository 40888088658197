import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HamburgerMenuIcon from './icons/HamburgerMenuIcon';
import Link from "./Link";

export default ({list, ...other}) => {
  const theme = useTheme();
  const { sx, ...props } = other;
  //const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const desktop = useMediaQuery('@media (min-width:1000px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const items = React.useMemo(() => {
    if(desktop){
      return list.map(item => {
        return (
          <Box component={'li'} sx={item.sx} key={item.link}>
            <Box component={Link} style={{ textDecoration: 'none'}} to={item.link} target={item.target}>
              <Typography color="common.black" variant={'body2'}>{item.label}</Typography>
            </Box>
          </Box>
        );
      });
    }else{
      return list.map(item => {
        return (
          <MenuItem onClick={handleClose} key={item.link} sx={{p: theme.spacing(6), ...item.sx}}>
            <Link style={{ textDecoration: 'none' }} to={item.link} target={item.target}>
              <Typography color="common.black" variant={'body2'}>{item.label}</Typography>
            </Link>
          </MenuItem>
        );
      });
    }
  }, [list, desktop, handleClose]);

  if(desktop){
    return (
      <Box component='nav'
        sx={{
          display: 'block',
          height: 71,
          width: 760,
          '& ul': {
            margin: 0,
            padding: 0,
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
          },
          '& ul li': {
            display: 'inline-block',
            // lineHeight: '71px',
            whiteSpace: 'nowrap',
            flex: '1 0 auto',
          },
          // '& ul li:not(:last-child)': {
          //   mr: 12,
          // },
          ...sx
        }}
        {...props}
      >
        <ul style={{paddingTop: theme.spacing(8)}}>
          {items}
        </ul>
      </Box>
    );
  }else{
    return (
      <Box component='nav' sx={sx}>
        <Box
          sx={{
            padding: 0,
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={handleClick}><HamburgerMenuIcon />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {items}
        </Menu>
      </Box>
    )
  }
};
