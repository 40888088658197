import React from 'react';

export default ({level}) => {
  switch(level && level.toLowerCase()){
    case 'beginner':
      return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 2L18.1432 11.6738H28.3148L20.0858 17.6525L23.229 27.3262L15 21.3475L6.77101 27.3262L9.9142 17.6525L1.68521 11.6738H11.8568L15 2Z" stroke="#FCFCFB" strokeWidth="2.5" strokeLinejoin="round"/>
        </svg>
      )
    case 'intermediate':
      return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M16.1887 1.61373C16.0213 1.0987 15.5414 0.75 14.9998 0.75C14.4583 0.75 13.9784 1.0987 13.811 1.61373L10.9485 10.4238H1.68506C1.14352 10.4238 0.663578 10.7725 0.496235 11.2875C0.328893 11.8025 0.512215 12.3667 0.950324 12.685L8.44459 18.1299L5.58203 26.94C5.41469 27.455 5.59801 28.0192 6.03612 28.3375C6.47423 28.6558 7.06748 28.6558 7.50559 28.3375L14.9998 22.8926L22.4941 28.3375C22.9322 28.6558 23.5255 28.6558 23.9636 28.3375C24.4017 28.0192 24.585 27.455 24.4177 26.94L21.5551 18.1299L29.0494 12.685C29.4875 12.3667 29.6708 11.8025 29.5035 11.2875C29.3361 10.7725 28.8562 10.4238 28.3146 10.4238H19.0512L16.1887 1.61373ZM13.0455 12.06L14.9998 6.04508L16.9542 12.06C17.1216 12.5751 17.6015 12.9238 18.143 12.9238H24.4675L19.3509 16.6412C19.2149 16.74 19.1034 16.8626 19.0194 17H10.9803C10.8963 16.8626 10.7848 16.74 10.6488 16.6412L5.53216 12.9238H11.8567C12.3982 12.9238 12.8781 12.5751 13.0455 12.06Z" fill="#FCFCFB"/>
        </svg>
      )
    case 'advanced':
      return (
        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.9511 0.690983C15.8172 0.27896 15.4332 0 15 0C14.5668 0 14.1828 0.27896 14.049 0.690983L11.1303 9.67376H1.68522C1.25199 9.67376 0.868037 9.95272 0.734163 10.3647C0.600289 10.7768 0.746947 11.2281 1.09743 11.4828L8.73864 17.0344L5.81996 26.0172C5.68609 26.4292 5.83275 26.8806 6.18323 27.1353C6.53372 27.3899 7.00832 27.3899 7.3588 27.1353L15 21.5836L22.6412 27.1353C22.9917 27.3899 23.4663 27.3899 23.8168 27.1353C24.1673 26.8806 24.3139 26.4292 24.1801 26.0172L21.2614 17.0344L28.9026 11.4828C29.2531 11.2281 29.3997 10.7768 29.2659 10.3647C29.132 9.95272 28.748 9.67376 28.3148 9.67376H18.8698L15.9511 0.690983Z" fill="#FCFCFB"/>
        </svg>
      )
  }
}
