import React from "react";
import styled from "styled-components";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

export const CustomButton = ({style, children, link, color, ...other}) => {
  const { sx } = other;
  return (
    <Button /*disableElevation*/ component={Link} href={link} color={color} style={style} sx={sx} variant='contained'>{children}</Button>
  );
};
