import React from 'react';
import styled from "styled-components";
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ArrowIcon } from './icons'

export default ({children, url, secondary, ...other}) => {
  const theme = useTheme();
  const style = React.useMemo(() => {
    return {
      textDecoration: `underline ${theme.palette.primary.main} solid ${theme.spacing(1)}`,
    }
  }, [theme])

  const color = React.useMemo(() => {
    return secondary ? 'secondary.contrastText' : 'common.black';
  }, [secondary])

  const handleClick = () => {
    window.location = url;
  }

  return (
    <Box component={'a'} href={url}>
      <Box component={'div'} sx={{display: 'inline-block'}}>
        <Typography style={style} color={color} onClick={handleClick}>{children}</Typography>
      </Box>
      <Box component={'div'} sx={{display: 'inline-block', marginLeft: 3}}>
        <ArrowIcon/>
      </Box>
    </Box>
  )
}
