import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

function GlossaryTabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      //id={`simple-tabpanel-${index}`}
      aria-labelledby={`filter-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 2, border: `${theme.palette.primary.main} solid`, borderWidth: 4 }}>
        {children}
      </Box>
    </div>
  );
}

export default GlossaryTabPanel;
