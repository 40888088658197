import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import TriangleIcon from './icons/TriangleIcon';
import GlossaryContent from './GlossaryContent';
import Grid from '@mui/material/Grid';

export default (props) => {
  const { onOpen, onClose, termName, isOpen = false, ...other } = props;
  const [open, setOpen] = React.useState(isOpen);
  const handleClick = React.useCallback(() => {
    if(open){
      onClose && onClose();
    }else{
      onOpen && onOpen();
    }
    setOpen(!open);
  }, [onOpen, onClose, setOpen, open])

  React.useEffect(() => {
    if(isOpen){
      setOpen(isOpen);
    }
  }, [isOpen]);

  if(termName && termName.length){
    return (
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemText primary={termName} />
          <Box container="div" sx={{width: '36px'}}>
            <TriangleIcon open={open}/>
          </Box>
        </ListItemButton>
        <Collapse in={open} unmountOnExit>
          <GlossaryContent {...other}/>
        </Collapse>
      </>
    );
  }
  // else {
  //   return (
  //     <Box component={'div'} sx={{p: 4, height: '80px', width: '100%'}}>
  //       <Skeleton variant="text" sx={{ width: '100%'}} />
  //     </Box>
  //   )
  // }
}
