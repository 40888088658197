import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlossaryTabPanel from './GlossaryTabPanel';

export default function GlossaryFilterTabs(props) {
  const theme = useTheme();
  const { value, onChange, ...other } = props;
  const desktop = useMediaQuery('@media (min-width:1000px)');
  const pdfTabLabel = React.useMemo(() => {
    if(desktop){
      return 'Save as pdf';
    }else {
      return 'Pdf';
    }
  }, [desktop])


  return (
    <Box {...other}>
      <Tabs
        value={value}
        variant={'fullWidth'}
        onChange={onChange}
        aria-label="wrapped label tabs example"
        sx={{
          '.MuiTabs-indicator': {
            //height: '4px',
            display: 'none'
          },
        }}
      >
        <Tab sx={theme.typography.body1} value="search" label="Search"/>
        <Tab sx={theme.typography.body1} value="filter" label="Filter" />
        <Tab sx={theme.typography.body1} value="pdf" label={pdfTabLabel} />
      </Tabs>
    </Box>
  );
}

GlossaryFilterTabs.propTypes = {
  onChange: PropTypes.func,
};
