import React from 'react';
import Home from './Home';
import Glossary from './Glossary';
import GlossaryFullscreen from './GlossaryFullscreen';
import About from './About';
import Contribute from './Contribute';
import Learn from './Learn';
import LearnLevelSwitch from './LearnLevelSwitch';
import LearnThemeSwitch from './LearnThemeSwitch';
import PageNotFound from './PageNotFound';
import MuiTheme from './components/MuiTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import useInit from './hooks/useInit';
import LoadingAnimation from './components/LoadingAnimation';

function App() {
  //console.debug('React version', React.version);
  const initData = useInit();
  if(initData){
    return (
      <div className="App">
        <MuiTheme>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home data={initData.home}/>} />
            <Route path="/glossary" element={<Glossary data={initData.glossary}/>} />
            <Route path="/glossary/fullscreen" element={<GlossaryFullscreen />} />
            <Route path="/about" element={<About data={initData.about}/>} />
            <Route path="/contribute" element={<Contribute data={initData.contribute}/>} />
            <Route path="/learn" element={<Learn data={initData.learn}/>} />
            <Route path="/learn/level" element={<LearnLevelSwitch data={initData.learnLevels} />} />
            <Route path="/learn/theme" element={<LearnThemeSwitch data={initData.learnThemes} />} />
            <Route
              path="*"
              element={<PageNotFound />}
            />
          </Routes>
        </MuiTheme>
      </div>
    );
  }else{
    return <LoadingAnimation />
  }
}

export default App;
