import axios from 'axios';
import { getApiBaseUrl } from './misc';

// const port = process.env.API_PORT || 1337;
// const host = process.env.API_HOST || 'localhost';
// const protocol = process.env.API_PROTOCOL || 'http';
//
// const baseUrl = `${protocol}://${host}` + (port ? `:${port}/` : '/');
const baseUrl = getApiBaseUrl();

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  paramsSerializer(params) {
    const searchParams = new URLSearchParams()
    for (const key of Object.keys(params)) {
      const param = params[key]
      if (Array.isArray(param)) {
        for (const p of param) {
          searchParams.append(key, p)
        }
      } else {
        searchParams.append(key, param)
      }
    }
    return searchParams.toString()
  },
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
