import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

function TermProperties(props) {
  const theme = useTheme();
  const { icon, text, color, link, minHeight = 79 } = props;

  if(link){
    return (
      <Button
        component={Link}
        href={link}
        target={'_blank'}
        variant='custom2'
        color={theme.palette.primary.contrastText}
        sx={{
          display: 'flex',
          minHeight: minHeight,
          flexBasis: '100%',
          '-webkit-box-flex': 0,
          flexGrow: 0,
          maxWidth: '100%',
          width: '100%',
          flexWrap: 'wrap',
          flexDirection: 'row',
          backgroundColor: color,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
          }
        }}
      >
        <Grid className={'icon'} item sx={{px: 4}}>
          {icon}
        </Grid>
        <Grid item sx={{flex: 1, textAlign: 'center'}}>
          <span>{text}</span>
        </Grid>
      </Button>
    )
  }else{
    return (
      <Grid container item xs={12} alignContent={'center'} sx={{backgroundColor: color, minHeight: minHeight}}>
        <Grid className={'icon'} item sx={{px: 4}}>
          {icon}
        </Grid>
        <Grid item sx={{flex: 1, textAlign: 'center'}}>
          <Typography variant={'custom2'} color={theme.palette.primary.contrastText}>{text}</Typography>
        </Grid>
      </Grid>
    )
  }
}

export default TermProperties;
