import React from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LogoIcon from './components/icons/LogoIcon';

export default ({data}) => {
  const theme = useTheme();
  const text = data;

  return (
    <>
      <Box sx={{marginTop: 30}}>
        <Box item xs={12} sx={{textAlign: 'center', '& svg': {height: 162, width: 298}}}>
          <LogoIcon />
        </Box>
      </Box>
      <Box sx={{marginTop: 30}}>
        <Box item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">404 Page not found!</Typography>
        </Box>
      </Box>
    </>
  );
};
