import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { getApiBaseUrl } from '../misc';

const apiBaseUrl = getApiBaseUrl();

export default ({relativePath, image}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const getFormat = (formats, mobile) => {
      // if(!formats){
      //   throw 'Strapi Responsive friendly upload seems disabled!';
      // }
      if(mobile){
        if(formats.medium){
          return formats.medium.url;
        }
      }else{
        if(formats.xlarge){
          return formats.xlarge.url;
        }else if(formats.large){
          return formats.large.url;
        }  
      }
      return null;
    }

    const path = React.useMemo(() => {
      if(image){
        const { formats, url } = image.data.attributes;
        const formatsUrl = getFormat(formats, mobile);
        if(formatsUrl){
          return formatsUrl;
        }else{
          return url;
        }
      }else{
        return relativePath;
      }
    }, [image, relativePath, mobile]);

    return (
      <Box
        component={'div'}
        sx={{
          height: 320,
          width: '100%',
          background:
          `url(${apiBaseUrl + path}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          [theme.breakpoints.down('md')]: {
            height: 183,
          },

        }} />
    )
}
