import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Navigation from './Navigation';
import LogoIcon from './icons/LogoIcon';
import styled from "styled-components";

export default ({}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const largeDown = useMediaQuery(theme.breakpoints.down('lg'));
  const headerPadding = React.useMemo(()=>{
    if(mobile){
      return theme.spacing(16);
    }else{
      if(largeDown){
        return theme.spacing(18 / 2);
      }else{
        return theme.spacing(18);
      }
    }
  }, [largeDown, theme, mobile])
  return (
    <Box
      component={'header'}
      sx={{
        //py: theme.spacing(18),
        height: 144,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          height: 87,
        },
      }}
    >
      <Box
        color='secondary'
        sx={{
          padding: 0,
          position: 'absolute',
          left: theme.spacing(18),
          top: theme.spacing(8),
          [theme.breakpoints.down('lg')]: {
            left: theme.spacing(8),
          }
        }}
      >
        <a href='/' style={{ lineHeight: 0}} ><LogoIcon mobile={mobile}/></a>
      </Box>
      <Navigation
        sx={{
          position: 'absolute',
          right: theme.spacing(18),
          top: theme.spacing(8),
          [theme.breakpoints.down('lg')]: {
            right: theme.spacing(8)
          }
        }}
        list={[
          { link: '/about', label: 'About' },
          { link: '/glossary', label: 'Find a word' },
          { link: '/learn', label: 'Learn about a topic' },
          { link: '/contribute', label: 'Contribute' },
          { link: 'https://www.weareutopia.co', label: 'Towards Utopia', sx: { '& a p': {color: 'rgba(0, 43, 73, 0.6)'} }, target: '_blank' }
        ]}
      />
    </Box>
  );
};
//
// const Header = styled.header`
//   padding: 32px 70px 32px 70px;
//   height: 144px;
//   position: relative;
// `;
