import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GlossaryFilterTabs from './GlossaryFilterTabs';
import GlossaryPdfPanel from './GlossaryPdfPanel';
import GlossarySearchPanel from './GlossarySearchPanel';
import GlossaryFilterPanel from './GlossaryFilterPanel';

function GlossaryControls(props) {
  const { onTabChange, checkboxGroups, onCheckboxChange, onSearchChange, printUrl, ...other } = props;
  const [value, setValue] = React.useState('search');

  const handleChange = React.useCallback((event, newValue) => {
    onTabChange(newValue);
    setValue(newValue);
  }, [onTabChange, setValue]);

  return (
    <>
      <GlossaryFilterTabs onChange={handleChange} value={value} /*sx={{paddingBottom: 4}}*/ />
      <GlossarySearchPanel onChange={onSearchChange} value={value} index={'search'} />
      <GlossaryFilterPanel onCheckboxChange={onCheckboxChange} checkboxGroups={checkboxGroups} value={value} index={'filter'} />
      <GlossaryPdfPanel printUrl={printUrl} value={value} index={'pdf'} />
    </>
  );
}

GlossaryControls.propTypes = {
  onCheckboxChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onPdfClick: PropTypes.func,
};

export default GlossaryControls;
