import React from "react";
import styled, { withTheme } from "styled-components";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Main from './Main';
import { CustomButton } from './components/CustomButton';
import EmphasisContainer from './components/EmphasisContainer';
import SiteGridContainer from './components/SiteGridContainer';
import NormalBox from './components/NormalBox';
import Hyperlink from './components/Hyperlink';
import HeadImage from './components/HeadImage';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useApi from './hooks/useApi';

export default ({data}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const text = data;

  return (
    <Main>
      <HeadImage {...data.subheader}/>
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(7), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <Typography>
            {text.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer justifyContent="center" sx={{marginTop: theme.spacing(17), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(13) }}}>
        <Grid item xs={6} sx={{paddingRight: theme.spacing(3)}}>
          <CustomButton
            link={text.buttons[0].link}
            sx={{width: '100%', height: '144px', [theme.breakpoints.down('md')]: { height: '120px'}}}
          >
            {text.buttons[0].label}
          </CustomButton>
        </Grid>
        <Grid item xs={6} sx={{paddingLeft: theme.spacing(3)}}>
          <CustomButton
            link={text.buttons[1].link}
            sx={{width: '100%', height: '144px', [theme.breakpoints.down('md')]: { height: '120px'}}}
          >
            {text.buttons[1].label}
          </CustomButton>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer
        container
        spacing={5}
        emphasis
        sx={{
            marginTop: theme.spacing(20),
            paddingTop: theme.spacing(23),
            paddingBottom: theme.spacing(28),
            [theme.breakpoints.down('md')]: {
              marginTop: theme.spacing(17),
              paddingTop: theme.spacing(5),
              paddingBottom: theme.spacing(9),
              '& > div': {
                padding: theme.spacing(9),
              }
            },
          }}
      >
        <Grid item md={6}>
          <Typography color="secondary.contrastText">
              {text.paragraph}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Grid container justifyContent={'center'} style={{alignItems: 'center', height: '100%'}}>
            <Grid item>
              <Hyperlink url={text.links[0].link} secondary>{text.links[0].label}</Hyperlink>
            </Grid>
          </Grid>
        </Grid>
      </SiteGridContainer>
    </Main>
  );
};
