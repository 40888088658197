
export const mapColumns = (dataList, sheetColumnMap) => {
  return dataList.map(item => {
    if(sheetColumnMap && sheetColumnMap.levels){
      const found = sheetColumnMap.levels.find(level => level.key === item.attributes.maturity);
      if(found){
        const { value } = found;
        return { ...item.attributes, maturity: value ? value : item.attributes.maturity, id: item.id };
      }
    }
    return { ...item.attributes, id: item.id };
  })
}

export const formatExcludeMutable = (column, attributes) => {
  column.split(',')
  .map(text => {
    const noSpace = text.replaceAll(' ', '');
    return noSpace[0].toLowerCase() + noSpace.slice(1)}
  )
  .forEach(field => attributes[field] = undefined);

  return column;
}
