import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export default ({style, children, emphasis, ...other}) => {
  const theme = useTheme();
  const {sx} = other;
  const defaultStyle = React.useMemo(()=>({
    width: '870px',
    margin: 'auto',
    [theme.breakpoints.down('md')]:{
      width: '100%',
      margin: 0,
      px: theme.spacing(8),
    }
  }), [theme]);

  const emphasisColor = React.useMemo(() => {
    if(!theme){
      return;
    }
    return theme.palette.secondary.main;
  }, [theme, emphasis])

  //console.log('SiteGridContainer: ', {...defaultStyle, ...sx});
  if(emphasis){
    return (
      <Box component={'div'} sx={{backgroundColor: emphasisColor, width: '100%'}}>
        <Grid {...other} container sx={[{...defaultStyle}, {...sx}]}>
          {children}
        </Grid>
      </Box>
    )
  }else{
    return (
      <Grid {...other} container sx={[{...defaultStyle}, {...sx}]}>
        {children}
      </Grid>
    );
  }
};
