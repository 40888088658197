import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import useQuery from './hooks/useQuery';
import Main from './Main';
import HeadImage from './components/HeadImage';
import FullscreenList from './components/FullscreenList'
import GlossaryControls from './components/GlossaryControls'
import LogoIcon from './components/icons/LogoIcon';
import { debounce } from './misc';

const PRINT_URL_BASE = process.env.REACT_APP_PRINT_URL || '/api/glossary/print';

export default () => {
  const theme = useTheme();
  const query = useQuery();
  const filtersUrlBase = '/api/google-sheets';
  const searchUrlBase = '/api/google-sheets/glossary';

  const type = React.useMemo(()=> {
    return query.get('type');
  }, [query]);

  const listUrl = React.useMemo(() => {
    if(type == 'search' && query.get('term')){
      return `${searchUrlBase}?${query.toString()}`;
    }else{
      const search = new URLSearchParams(query);
      search.set('sort', 'termName');
      return `${filtersUrlBase}?${search.toString()}`;
    }
  }, [type, query])

  return (
    <>
      <Box sx={{marginTop: 25}}>
        <Box item xs={12} sx={{textAlign: 'left'}}>
          <Typography variant="h3">INCLUSION GLOSSARY</Typography>
        </Box>
      </Box>
      <Box sx={{marginTop: 25}}>
        <Box item xs={12} sx={{textAlign: 'left'}}>
          <Typography variant="print1">This glossary is not intended as an exhaustive list but rather as a living, breathing resource which will grow and change as new terms emerge, language moves from community into common usage or indeed outgrows its usefulness.</Typography>
        </Box>
      </Box>
      <Box className={'page-break-after'} sx={{marginTop: 50}}>
        <Box item xs={12} sx={{textAlign: 'left'}}>
          <Typography variant="print1">{`${(new Date()).getDate()} / ${(new Date()).getMonth()+1} / ${(new Date()).getYear()+1900}`}</Typography>
        </Box>
      </Box>
      <Box sx={{marginTop: 25}}>
        <Box item xs={12} sx={{textAlign: 'left', '& a': { color: theme.palette.common.black }}}>
          <Typography variant="custom1">
            <span>To create this Glossary we have used root sources such as</span>
            <ul>
              <li><a href='https://www.oed.com/'>Oxford English Dictionary</a></li>
              <li><a href='https://dictionary.cambridge.org/dictionary/english/'>Cambridge English Dictionary</a></li>
              <li><a href='https://www.stonewall.org.uk/'>Stonewall</a></li>
              <li><a href='https://www.york.ac.uk/about/equality/edi-glossary-terminology/'>University of York sources</a></li>
              <li><a href='https://www.legislation.gov.uk/ukpga/2010/15/contents'>The Equality Act 2010 (UK)</a></li>
              <li><a href='https://www.who.int/'>World Health Organisation</a></li>
              <li><a href='https://www.dyslexia.uk.net/'>The Dyslexia Association</a></li>
              <li><a href='https://www.gov.uk/'>Gov.uk</a></li>
              <li><a href='https://www.equalityhumanrights.com/en'>Equalities and Human Rights Commission</a></li>
              <li><a href='https://www.changeyourmindni.org/'>Change Your Mind</a></li>
              <li><a href='https://www.verywellmind.com/'>The Very Well Mind</a></li>
              <li><a href='https://refuge.org.uk/?gclid=Cj0KCQiA_P6dBhD1ARIsAAGI7HCqa7y5rYbLu8cWxMs3MAiXRhEKlOjxjMLSStmwYeRvG3Rfswj6KTMaAn8PEALw_wcB'>Refuge</a></li>
              <li><a href='https://www.womensaid.org.uk/?gclid=Cj0KCQiA_P6dBhD1ARIsAAGI7HAkuMKBYT16RPbWUgDMGc4Xw8TMqpARKPbOSsz02J3TGevSCuhzvacaAlJTEALw_wcB'>Women’s Aid</a></li>
            </ul>
            <span>and others</span>
          </Typography>
        </Box>
      </Box>
      <Box className={'page-break-after'} sx={{marginTop: 12}}>
        <Box item xs={12} sx={{textAlign: 'left'}}>
          <Typography variant="custom1">We have combined all this with our professional experience and provided what we believe is a contemporary understanding of the terminology for the UK in 2023. This is a living project which we intend to maintain as our language evolves. If you would like to contribute to it, please feel free to contact us at </Typography>
          <a style={{color: theme.palette.common.black}} href="mailto:glossary@weareutopia.co?subject=Contribute to the glossary">
            <Typography variant="custom1">glossary@weareutopia.co</Typography>
          </a>
        </Box>
      </Box>
      <FullscreenList url={listUrl} />
    </>
  );
};
