import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveListBase from './ProgressiveListBase';
import GlossaryItem from './GlossaryItem';
import useApi from '../hooks/useApi';
import { mapColumns, formatExcludeMutable } from './listUtils';
//import MuiCheckbox from '@mui/material/Checkbox';
//import CheckedIcon from './icons/CheckedIcon';

const setupUrl = (pageSize, page, url) => {
  const splitUrl = url.split('?');
  let searchParams = splitUrl.length > 1 ? new URLSearchParams(splitUrl[1]) : new URLSearchParams();
  searchParams.set('pagination[page]', page);
  searchParams.set('pagination[pageSize]', pageSize);
  return `${splitUrl[0]}?${searchParams.toString()}`;
};

function ProgressiveList(props) {
  const { parent, url, pageSize = 25 } = props;
  const startPage = 1;
  const [updateUrl, setUpdateUrl] = React.useState(setupUrl(pageSize, startPage, url));
  const [data, setData] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(startPage + 1);

  const sheetColumnMap = useApi('/api/sheet-column?populate=categories,levels', result => {
    return {
      ...result.data.attributes,
    }
  }, {});

  const dataMapHandler = React.useCallback(result => {
    // console.debug('updateUrl:', updateUrl);
    //Let's convert human text table columns into camelCaseNames
    const items = result.data.map(row => {
      const item = { ...row };
      // if(item.attributes.exclude){
      //   formatExcludeMutable(item.attributes.exclude, item.attributes);
      // }
      return item;
    });
    return items;
  }, [])

  React.useEffect(() => {
    const anotherUrl = setupUrl(pageSize, startPage, url);
    if(updateUrl !== anotherUrl){
      setData([]);
      setUpdateUrl(anotherUrl);
      setNextPage(startPage + 1);
    }
//    console.debug('url:', url)
  }, [url])

  const update = useApi(updateUrl, dataMapHandler, data);

  React.useEffect(() => {
    if(Object.keys(sheetColumnMap).length){
      setData(data.concat(mapColumns(update, sheetColumnMap)));
    }
  }, [update, sheetColumnMap])

  const onUpdate = React.useCallback(ev => {
    //const { nextPage } = ev;
    if(data.length > 0){
      setUpdateUrl(setupUrl(pageSize, nextPage, url));
      setNextPage(nextPage + 1);
    }
  }, [url, pageSize, nextPage, data]);

  const list = React.useMemo(() => {
    return data.map(item => {
      return (
        <GlossaryItem
          {...item}
          key={item.id}
          termName={item.termName}
          definition={item.dictionaryDefinition}
          definitionSource={item.definitionRootSource}
          context={item.context}
          communityInput={item.communityInput}
          category={item.category}
          regionality={item.regionality}
          usage={item.usage}
          isProtected={item.protectedCharacteristic}
          link={item.furtherReading}
        />
      );
    });
  }, [data]);

  return (
    <ProgressiveListBase
      data={data}
      parent={parent}
      onUpdate={onUpdate}
    >
      {list}
    </ProgressiveListBase>
  );
}

ProgressiveList.propTypes = {
  url: PropTypes.string,
};

export default ProgressiveList;
