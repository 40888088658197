import React from 'react';
import axiosInstance from '../axios';

const useApi = (url, mapHandler, defaultData, cached = false) => {
  //const base64 = url;//const base64 = btoa(url);
  //const stored = window.localStorage.getItem(base64);
  const [data, setData] = React.useState(defaultData);//const [data, setData] = React.useState(JSON.parse(stored) || defaultData);

  React.useEffect(() => {
    const controller = new AbortController();

    if(!url){
      return;
    }

    const callback = async () => {
      try {
        const result = await axiosInstance.get(url, {
           signal: controller.signal
        });
        if(result.status !== 200){
          return;
        }

        if(result.data && mapHandler){
          const mapped = mapHandler(result.data)
          setData(mapped);
          //window.localStorage.setItem(base64, JSON.stringify(mapped));
        }else{
          setData(result);
          //window.localStorage.setItem(base64, JSON.stringify(result));
        }
      } catch (e) {
        if(controller.signal.aborted){
          return defaultData;
        }
        throw e;
      }
    }

    //if(!cached || !stored){
      callback();
    //}
    return () => {
      controller.abort();
    }
  }, [url, cached]);

  return data;
};

export default useApi;
