import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { useTheme } from '@mui/material/styles';

const Main = ({children}) => {
  const theme = useTheme();
  React.useEffect(()=>{
    window.muitheme = theme;
  }, [theme])
  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Main;
