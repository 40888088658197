import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { MaturityLevelIcon, ReadMoreIcon, RegionalityIcon, UsageIcon, CategoryIcon } from './icons';
import TermProperties from './TermProperties';

import './GlossaryContent.css';

export default ({definition, definitionSource, context, communityInput, category, usage, regionality, maturity, isProtected, link}) => {
  const theme = useTheme();
  const boxProperties = React.useMemo(()=> {
    return [
      { value: `Category: ${category}`, icon: <CategoryIcon value={category}/>, color: '#002B49', disable: (category ? false : true) },
      { value: `Current Use: ${usage}`, icon: <UsageIcon value={usage}/>, color: '#4D6B80', disable: (usage ? false : true) },
      { value: `Regionality: ${regionality}`, icon: <RegionalityIcon />, color: '#AD8DD9', disable: (regionality ? false : true) },
      { value: `Level: ${maturity}`, icon: <MaturityLevelIcon level={maturity && maturity.toLowerCase()} />, color: '#C19BF2', disable: (maturity ? false : true) },
      { value: 'Read more', icon: <ReadMoreIcon />, color: theme.palette.primary.main, disable: (link ? false : true), link: link },
    ].filter(item => !item.disable);
  }, [category, usage, regionality, maturity, link]);

  const items = React.useMemo(() => {
    return boxProperties.map((item, index) => {
      return (
        <Grid className={'no-break-inside'} key={index} item xs={12} md={6} sx={{px: 2.5, py: 2}}>
          <TermProperties color={item.color} text={item.value} icon={item.icon} link={item.link}/>
        </Grid>
      )
    });
  },[boxProperties, theme]);

  return (
    <Grid className={'print-block'} container sx={{px: theme.spacing(3), py: theme.spacing(6), textAlign: 'unset'}}>
      {definition ? (
        <Grid className={'no-break-inside'} item xs={12} sx={{textAlign: 'left'}}>
          <Typography variant={'custom1'}>{definition}</Typography>
        </Grid>
      ) : null}
      {context ? (
        <Grid className={'no-break-inside'} item xs={12} sx={{marginTop: theme.spacing(12), textAlign: 'left'}}>
          <Typography variant={'custom3'}>{context}</Typography>
        </Grid>
      ) : null}
      {communityInput ? (
        <Grid className={'no-break-inside'} item xs={12} sx={{marginTop: theme.spacing(14), textAlign: 'left'}}>
          <Typography variant={'custom4'}>{communityInput}</Typography>
        </Grid>
      ) : null}
      <Grid className={'print-block'} container item justifyContent="center" sx={{marginTop: theme.spacing(17)}}>
        {items}
      </Grid>
      {definitionSource ? (
        <Grid className={'no-break-inside'} item xs={12} sx={{textAlign: 'center', my: theme.spacing(8)}}>
          <Typography sx={{fontSize: '12px'}} variant={'custom2'}>{`Definition Root Source: ${definitionSource}`}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}
