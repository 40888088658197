import React from 'react';
import { useTheme } from '@mui/material/styles';
//import SvgIcon from '@mui/material/SvgIcon';

export default ({...other}) => {
  const { width, height } = other;
  const theme = useTheme();
  const defaultColor = React.useMemo(() => ({backgroundColor: undefined, color: theme.palette.primary.main}));
  const [color, setColor] = React.useState(defaultColor);
  const handleMouseOver = React.useCallback(() => {
    setColor({backgroundColor: theme.palette.primary.main, color: theme.palette.common.black})
  }, [theme])

  const handleMouseOut = React.useCallback(() => {
    setColor(defaultColor)
  }, [defaultColor])

  return (
    <svg {...other} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="28.5" cy="28" rx="28.5" ry="28" fill={`${color.backgroundColor}`}/>
      <path d="M56 28C56 42.8951 43.7046 55 28.5 55C13.2954 55 1 42.8951 1 28C1 13.1049 13.2954 1 28.5 1C43.7046 1 56 13.1049 56 28Z" stroke={`${theme.palette.primary.main}`} strokeWidth="2"/>
      <path d="M22 18.5C22 19.8748 21.1246 21 19.5003 21C17.9999 21 17 19.8748 17 18.6245C17 17.2497 17.9999 16 19.5003 16C21.0001 16 22 17.1252 22 18.5Z" fill={`${color.color}`}/>
      <path d="M34.5453 23C31.9421 23 30.4547 24.4998 29.8347 25.5003H29.7106L29.463 23.3748H25C25 24.7501 25.1241 26.3751 25.1241 28.2503V39H30.0823V30.1249C30.0823 29.625 30.0823 29.2502 30.2064 28.8748C30.5781 28 31.1981 26.875 32.5621 26.875C34.2978 26.875 35.0412 28.3748 35.0412 30.3752V39H40V29.7502C40 25.1249 37.6444 23 34.5453 23Z" fill={`${color.color}`}/>
      <path d="M17 39H22V22H17V39Z" fill={`${color.color}`}/>
    </svg>
  )
};
