import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from './Checkbox';
import GlossaryTabPanel from './GlossaryTabPanel';
import './GlossaryFilterPanel.css';

function GlossaryFilterPanel(props) {
  const { value, index, checkboxGroups, onCheckboxChange, ...other } = props;

  const groups = React.useMemo(() => {
    return checkboxGroups.map(item => {
      const groupName = item.name;
      const groupType = item.type;
      const groupLabels = item.labels;

      const labels = groupLabels.map(label => {
        const handleOnChange = ev => {
          const checked = ev.target.checked;
          onCheckboxChange && onCheckboxChange({group: groupType, name: groupName, label, checked});
        }
        return (<FormControlLabel key={`${groupType}${label.value}`} control={<Checkbox onChange={handleOnChange} /*defaultChecked*/ />} label={label.label} />);
      });

      return (
        <>
          <Typography sx={{marginLeft: 2, marginBottom: 2}}>{groupName}</Typography>
          <FormGroup sx={{px: 3}}>
            {labels}
          </FormGroup>
        </>
      );
    })
  }
  , [checkboxGroups, onCheckboxChange]);

  return (
    <GlossaryTabPanel value={value} index={index} >
      <Grid
        container
        sx={{ p: 3 }}
      >
        {groups.map((group, index) => <Grid key={index} className={'glossary-filter-group'} item xs={12} md={4}>{group}</Grid>)}
      </Grid>
    </GlossaryTabPanel>
  );
}

GlossaryFilterPanel.propTypes = {
  onCheckboxChange: PropTypes.func,
};

GlossaryFilterPanel.defaultProps = {
  checkboxGroups: [],
};

export default GlossaryFilterPanel;
