import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import Hyperlink from './components/Hyperlink';
import { CustomButton } from './components/CustomButton';

export default ({data}) => {
  const theme = useTheme();
  const text = data;

  const items = data.buttons.map(item => {
    return (
      <Grid key={item.label} item xs={12} md={4} sx={{px: 2, py: 2, textAlign: 'center'}}>
        <CustomButton
          link={item.link}
          sx={{width: '100%', minHeight: '122px', [theme.breakpoints.down('md')]: { minHeight: '120px'}}}
        >
          {item.label}
        </CustomButton>
      </Grid>
    )
  })

  return (
    <Main>
      <HeadImage {...data.subheader} />
      <SiteGridContainer sx={{marginTop: 12, [theme.breakpoints.down('md')]: { marginTop: 12 }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(23), marginBottom: theme.spacing(10), [theme.breakpoints.down('md')]: { marginTop: 10 }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h4">
            {text.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer justifyContent="center" sx={{marginTop: 4, marginBottom: 23, [theme.breakpoints.down('md')]: { marginTop: 6, marginBottom: 14 }}}>
        {items}
      </SiteGridContainer>
    </Main>
  );
};
