import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
//import MuiCheckbox from '@mui/material/Checkbox';
import GlossaryItem from './GlossaryItem';

function ProgressiveListBase(props) {
  const { children, onUpdate, parent } = props;
  const [nextPageNumber, setNextPageNumber] = React.useState(2);

  return (
    <InfiniteScroll
      dataLength={children.length}
      next={onUpdate}
      hasMore={true}
      scrollableTarget={parent}
      //loader={<GlossaryItem />}
    >
      {children}
    </InfiniteScroll>
  );
}

ProgressiveListBase.propTypes = {
  onUpdate: PropTypes.func,
};

export default ProgressiveListBase;
