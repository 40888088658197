import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import ProgressiveList from './components/ProgressiveList'
import GlossaryControls from './components/GlossaryControls'
import { debounce } from './misc';

const PRINT_URL_BASE = process.env.REACT_APP_PRINT_URL || '/api/glossary/print';

export default ({data}) => {
  const searchDebounceTimer = React.useRef();
  const filtersUrlBase = '/api/google-sheets?sort=termName';
  const searchUrlBase = '/api/google-sheets/glossary';
  const printUrlBase = PRINT_URL_BASE;
  const [printType, setPrintType] = React.useState('search');
  const [filters, setFilters] = React.useState(new Map());
  const [listUrl, setListUrl] = React.useState(filtersUrlBase);
  const [searchUrl, setSearchUrl] = React.useState(filtersUrlBase);
  const [filterUrl, setFilterUrl] = React.useState(filtersUrlBase);
  const [printUrl, setPrintUrl] = React.useState(printUrlBase);
  const theme = useTheme();
  const text = data;

  const filterLabels = useApi('/api/google-sheets/filters', result => { return result.data }, []);
  const sheetColumnMap = useApi('/api/sheet-column?populate=categories,levels', result => {
    return {
      ...result.data.attributes,
    }
  });

  const filterMap = React.useMemo(() => {
    if(sheetColumnMap && filterLabels){
      const map = new Map(sheetColumnMap.levels.map(item => [item.key, item.value]));
      return filterLabels.map((item, index) => {
        if(item.type === 'maturity'){
          return { ...item, labels: sheetColumnMap.levels.map(level => ({ label: map.get(level.key), value: level.key })) }
        }else{
          return { ...item, labels: item.labels.map(label => ({label: label, value: label })) }
        }
      });
    }
  }, [filterLabels, sheetColumnMap]);

  const handleTabChange = React.useCallback(value => {
    switch(value){
      case 'filter':
        setListUrl(filterUrl);
        setPrintType(value);
        break;
      case 'search':
        setListUrl(searchUrl);
        setPrintType(value);
        break;
    }
  }, [setPrintType, filterUrl, searchUrl, setListUrl])

  const handleCheckboxChange = React.useCallback(data=>{
    const { group, label, checked } = data;
    const labelEncoded = encodeURIComponent(label.value);
    const entry = `filters[${group}][$eq]=${labelEncoded}`;
    filters.set(entry, checked);
    setFilters(new Map(filters))
    setPrintType('filter');
  }, [filters])

  const handleSearchChange = React.useCallback(ev => {
    const term = ev.currentTarget.value;
    clearTimeout(searchDebounceTimer.current);
    if(term){
      searchDebounceTimer.current = setTimeout(() => {
        const encoded = encodeURIComponent(term);
        const url = `${searchUrlBase}?` + `term=${encoded}`;
        setListUrl(url);
        setSearchUrl(url);
      }, 500);
    }else{
      setListUrl(filtersUrlBase);
      setSearchUrl(filtersUrlBase);
    }
    setPrintType('search');
  }, [setListUrl, searchUrlBase, filtersUrlBase, setSearchUrl])

  React.useEffect(()=>{
    const query = [...filters].filter(item => item[1]).map(item => item[0]).join('&');
    const url = filtersUrlBase + "&" + query;
    setListUrl(url);
    setFilterUrl(url);
  }, [filters, setFilterUrl])

  React.useEffect(() =>{
    const [address, query] = listUrl.split('?');
    setPrintUrl(`${printUrlBase}?${query}&type=${printType}`);
  }, [printUrlBase, listUrl, setPrintUrl, printType]);

  return (
    <Main>
      <HeadImage {...data.subheader}/>
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{px: 6}}>
          <GlossaryControls printUrl={printUrl} onTabChange={handleTabChange} onSearchChange={handleSearchChange} onCheckboxChange={handleCheckboxChange} checkboxGroups={filterMap}/>
        </Grid>
        <Grid item xs={12} sx={{marginTop: theme.spacing(20)}}>
          <ProgressiveList url={listUrl} />
        </Grid>
      </SiteGridContainer>
    </Main>
  );
};
