import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GlossaryContent from './GlossaryContent';
import useApi from '../hooks/useApi';
import { mapColumns, formatExcludeMutable } from './listUtils';

import './FullscreenList.css';

const setupUrl = (pageSize, page, url) => {
  const splitUrl = url.split('?');
  let searchParams = splitUrl.length > 1 ? new URLSearchParams(splitUrl[1]) : new URLSearchParams();
  searchParams.set('pagination[page]', page);
  searchParams.set('pagination[pageSize]', pageSize);
  return `${splitUrl[0]}?${searchParams.toString()}`;
};

function FullscreenList(props) {
  const { parent, url, pageSize = 100 } = props;
  const startPage = 1;
  const [updateUrl, setUpdateUrl] = React.useState(setupUrl(pageSize, startPage, url));
  const [data, setData] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(startPage + 1);
  const [ready, setReady] = React.useState(false);

  const sheetColumnMap = useApi('/api/sheet-column?populate=categories,levels', result => {
    return {
      ...result.data.attributes,
    }
  }, []);

  const dataMapHandler = React.useCallback(result => {
    // console.debug('updateUrl:', updateUrl);
    //Let's convert human text table columns into camelCaseNames
    const items = result.data.map(row => {
      const item = { ...row };
      // if(item.attributes.exclude){
      //   formatExcludeMutable(item.attributes.exclude, item.attributes);
      // }
      return item;
    });
    return items;
  }, [])

  React.useEffect(() => {
    const anotherUrl = setupUrl(pageSize, startPage, url);
    if(updateUrl !== anotherUrl){
      setData([]);
      setUpdateUrl(anotherUrl);
      setNextPage(startPage + 1);
    }
//    console.debug('url:', url)
  }, [url])

  const update = useApi(updateUrl, dataMapHandler);

  const onUpdate = React.useCallback(ev => {
    //const { nextPage } = ev;
    setUpdateUrl(setupUrl(pageSize, nextPage, url));
    setNextPage(nextPage + 1);
  }, [url, pageSize, nextPage, data]);

  React.useEffect(() => {
    if(update && update.length > 0 && Object.keys(sheetColumnMap).length){
      setData(data.concat(mapColumns(update, sheetColumnMap)));
    }
    if(update && update.length === 0){
      setReady(true)
    }
//    console.debug('update:', update)
}, [update, sheetColumnMap])

  React.useEffect(() => {
    if(data.length){
      onUpdate();
    }
  }, [data])

  React.useEffect(() => {
    if(ready){
      const nextFrame = () => {
        window.dispatchEvent(new CustomEvent('glossary-ready', { detail: 'ok'}));
      }
      window.requestAnimationFrame(nextFrame);
    }
  }, [ready])

  const list = React.useMemo(() => {
    return data.map(item => {
      return (
        <div key={item.id} className={'page-break-after'}>
          <Box sx={{padding: 2}}>
            <Typography variant='body1'>{item.termName}</Typography>
          </Box>
          <GlossaryContent
            {...item}
            isOpen={true}
            definition={item.dictionaryDefinition}
            definitionSource={item.definitionRootSource}
            context={item.context}
            communityInput={item.communityInput}
            category={item.category}
            regionality={item.regionality}
            usage={item.usage}
            isProtected={item.protectedCharacteristic}
            link={item.furtherReading}
          />
        </div>
      );
    });
  }, [data]);

  return (
    <div>
      {list}
    </div>
  );
}

FullscreenList.propTypes = {
  url: PropTypes.string,
};

export default FullscreenList;
