import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import Hyperlink from './components/Hyperlink';

export default ({data}) => {
  const theme = useTheme();
  const text = data;

  return (
    <Main>
      <HeadImage {...data.subheader} />
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(9), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <Typography>
            {text.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(12), marginBottom: theme.spacing(36), [theme.breakpoints.down('md')]: { my: theme.spacing(12)}}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <address>
            <Box component={'a'} sx={{textDecoration: 'none', fontStyle: 'normal'}} href={`mailto:${text.contactMail}?subject=${text.subheader.title}`} target={'_blank'}>
              <Typography color='primary'>
                {text.contactMail}
              </Typography>
            </Box>
          </address>
        </Grid>
      </SiteGridContainer>
    </Main>
  );
};
