import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import ProgressiveList from './components/ProgressiveList';
import GlossaryControls from './components/GlossaryControls'
import { debounce } from './misc';

const PRINT_URL_BASE = process.env.REACT_APP_PRINT_URL || '/api/glossary/print';

export default ({group, label}) => {
  const contentUrlBase = '/api/term-grpoup-pages?populate=subheader,subheader.image';
  const filtersUrlBase = '/api/google-sheets?sort=termName';
  const printUrlBase = `${PRINT_URL_BASE}?sort=termName`;
  const theme = useTheme();
  const data = useApi(`${contentUrlBase}&filters[name][$eq]=${encodeURIComponent(label)}`, result => {
    return {
      image: result.data[0].attributes.subheader.image.data && result.data[0].attributes.subheader.image.data.attributes.url,
      subheader: result.data[0].attributes.subheader,
    };
  }, { image: undefined, subheader: { title: undefined } });

  const style = React.useMemo(() => {
    return {
      textDecoration: `underline ${theme.palette.primary.main} solid ${theme.spacing(1)}`,
      color: 'black',
    }
  }, [theme]);

  const query = React.useMemo(() => {
    const lebelEncoded = encodeURIComponent(label);
    return `filters[${group}][$eq]=${lebelEncoded}`;
  }, [group, label])

  const listUrl = React.useMemo(() => {
    return filtersUrlBase + "&" + query;
  }, [query]);

  const printUrl = React.useMemo(() => {
    return `${printUrlBase}&${query}&type=filter`;
  }, [query]);

  return (
    <Main>
      <HeadImage {...data.subheader} />
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{data.subheader.title && data.subheader.title.replaceAll('/', '/ ')}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(9), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line'}}>
          <Typography variant="body3">
            {data.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(12)}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Box component={'a'} target={'_blank'} sx={style} href={printUrl}>
            <Typography variant="body1">Save as pdf</Typography>
          </Box>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: 13, marginBottom: 35, [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <ProgressiveList url={listUrl} />
        </Grid>
      </SiteGridContainer>

    </Main>
  );
};
