import React from 'react';
import useQuery from './hooks/useQuery';
import LearnTheme from './LearnTheme';
import TermsGroup from './TermsGroup';
import useApi from './hooks/useApi';
import axiosInstance from './axios';

export default ({ data }) => {
  const query = useQuery();
  const store = useApi('/api/sheet-column?populate=categories', result => {
    return {
      ...result.data.attributes,
    }
  }, { categories: [] }, true);

  const filters = useApi('/api/google-sheets/filters', result => { return result.data }, [], true);
  const termGroup = 'category';
  const value = React.useMemo(()=> {
    return query.get('value');
  }, [query]);

  const map = React.useMemo(() => {
    const instance = new Map(store.categories.map(item => [item.key, item.value]));
    const categories = filters.find(item => item.type === termGroup);
    if(categories){
      categories.labels.forEach(item => {
        if(!instance.has(item)){
          instance.set(item, item);
        }
      });
    }
    return instance;
  }, [store, filters])

  const buttons = React.useMemo(() => {
    return [...map.keys()].map(item => {
      return {
        label: map.get(item),
        link: `/learn/theme?value=${encodeURIComponent(item)}`,
      }
    })
  }, [map])

  if(value){
    return (
      <TermsGroup group={termGroup} label={value}/>
    );
  } else {
    return (
      <LearnTheme data={{...data, buttons}} />
    );
  }
};
