import React from "react";
import styled from "styled-components";
export default ({style, children}) => {
  return (
    <BoxContainer style={style}>
      {children}
    </BoxContainer>
  );
};
const BoxContainer = styled.div`
  width: 100%;
  font-size: 24px;
  background-color: #ffffff;
  font-family: Futura;
  font-weight: 700;
  color: #333333;
`;
