import React from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import LinkedinIcon from './icons/LinkedinIcon';
import UtopiaSiteIcon from './icons/UtopiaSiteIcon';
import InstagramIcon from './icons/InstagramIcon';
import LogoIcon from './icons/LogoIcon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from "styled-components";
export default ({}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const iconPadding = {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  };
  return (
    <Box component='footer'
      sx={{
        backgroundColor: theme.palette.secondary.contrastText,
        padding: '62px 0px 29px 0px',
        //height: 320,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          //height: 206,
          paddingTop: theme.spacing(11),
        }
      }}
    >

        <Box
          sx={{
            textAlign: 'center',
            width: '600px',
            //height: '90px',
            //lineHeight: '90px',
            py: 8,
            borderStyle: 'solid',
            borderWidth: '2px',
            margin: 'auto',
            borderColor: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
              width: '80%',
              //height: 60,
              //fontSize: '14px',
              //lineHeight: '60px',
            }
          }}
        >
          <Link
            sx={{
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.common.black,
              },
              width: '100%',
              height: '100%',
              display: 'inline-block',
              textDecoration: 'none'
            }}
            href='https://www.weareutopia.co/#block-1ab9a74be090b894a3f5'
            target="_blank"
          >
            Sign up to our newsletter
          </Link>
        </Box>
      <Container
        sx={{
          width: '600px',
          margin: 'auto',
          paddingTop: 13,
          paddingBottom: 17,
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            width: '80%',
            paddingTop: 6,
            paddingBottom: 4,
          }
        }}
      >
        <a style={iconPadding} target="_blank" href='https://www.instagram.com/weareutopians/'><InstagramIcon  style={mobile ? {height: '36px'} : {}} /></a>
        <a style={iconPadding} target="_blank" href='https://www.linkedin.com/company/weareutopia/'><LinkedinIcon  style={mobile ? {height: '36px'} : {}} /></a>
        <a style={iconPadding} target="_blank" href='https://www.weareutopia.co/'><UtopiaSiteIcon style={mobile ? {height: '36px'} : {}} /></a>
      </Container>
    </Box>
  );
};
