import * as React from 'react';

import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoItalic from '../fonts/Roboto-Italic.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: '#1CDCA4',
      light: '#69ffd9',
      dark: '#00af78',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#D9DFE1',
      light: '#91a6bf',
      dark: '#374b62',
      contrastText: '#002B49',
    },
    common: {
      black: '#333333', white: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      //'sans-serif',
    ].join(','),
    button: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '24px',
      textTransform: 'none',
      padding: 0,
    },
    h3: {
      fontWeight: 700,
      fontSize: '54px',
      lineHeight: '54px',
      padding: 0,
    },
    body1: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '24px',
      padding: 0,
    },
    body2: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '18px',
      padding: 0,
    },
    body3: {
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '24px',
      padding: 0,
    },
    custom1: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
      padding: 0,
    },
    custom2: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '32px',
      padding: 0,
    },
    custom3: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '29px',
      padding: 0,
    },
    custom4: {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontSize: '16px',
      lineHeight: '29px',
      padding: 0,
    },
    custom5: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '47px',
      padding: 0,
    },
    print1: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '72px',
      padding: 0,
    },
  },shape: {
    borderRadius: 0,
  },components: {
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'Proxima-Nova';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 400;
    //       src: url(${ProximaNovaReg}) format('truetype');
    //     }
    //     @font-face {
    //       font-family: 'Proxima-Nova';
    //       font-style: italic;
    //       font-display: swap;
    //       font-weight: 400;
    //       src: url(${ProximaNovaIt}) format('otf');
    //     }
    //     @font-face {
    //       font-family: 'Proxima-Nova';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 700;
    //       src: url(${ProximaNovaBold}) format('otf');
    //     }
    //     @font-face {
    //       fontFamily: 'Futura';
    //       fontStyle: normal;
    //       fontDisplay: swap;
    //       fontWeight: 700;
    //       src: url(${FuturaBold}) format('truetype');
    //     }
    //   `,
    // },
  }
});

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '30px',
  }
};

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('md')]: {
    fontSize: '50px',
    lineHeight: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '40px',
    lineHeight: '40px',
  }

};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '24px',
  }
};

theme.typography.button = {
  ...theme.typography.button,
  [theme.breakpoints.down('md')]: {
    lineHeight: '20px',
    fontSize: '20px',
  }
};

theme.typography.custom5 = {
  ...theme.typography.custom5,
  [theme.breakpoints.down('md')]: {
    lineHeight: '20px',
    fontSize: '20px',
  }
};

theme.components.MuiButton = {
  variants: [
    {
      props: { variant: 'custom2' },
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '32px',
        padding: 0,
        color: 'white',
      },
    },
  ]
}
// theme.components.MuiButton.variants = [{
//   props: { variant: 'custom2' },
//   style: {
//     fontFamily: 'Proxima-Nova',
//     fontWeight: 'bold',
//     fontSize: '16px',
//     lineHeight: '32px',
//     padding: 0,
//     color: theme.palette.primary.contrastText,
//   },
// }];


export const instance = theme;

export default ({children}) => {
  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}
