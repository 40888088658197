import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import Hyperlink from './components/Hyperlink';

export default ({data}) => {
  const theme = useTheme();
  const text = data;

  return (
    <Main>
      <HeadImage {...data.subheader} />
      <SiteGridContainer sx={{marginTop: theme.spacing(12), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }}}>
        <Grid item xs={12}sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(9), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <Typography>
            {text.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(28), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[0].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(21), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <Box component="div" sx={{borderBottom: `4px solid ${theme.palette.secondary.main}`, width: '117px', margin: 'auto'}} />
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(23), [theme.breakpoints.down('md')]: { my: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[1].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer
        container
        emphasis
        sx={{
            marginTop: theme.spacing(27),
            paddingTop: theme.spacing(29),
            paddingBottom: theme.spacing(35),
          }}
      >
        <Grid item md={12} sx={{whiteSpace: 'pre-line'}}>
          <Typography color="secondary.contrastText">
              {text.paragraphs[2].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(31), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[3].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(32), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12}>
          <Box component="div" sx={{borderBottom: `4px solid ${theme.palette.secondary.main}`, width: '117px', margin: 'auto'}} />
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(22), [theme.breakpoints.down('md')]: { my: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[4].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer
        container
        emphasis
        sx={{
            marginTop: theme.spacing(27),
            paddingTop: theme.spacing(29),
            paddingBottom: theme.spacing(35),
          }}
      >
        <Grid item md={12} sx={{whiteSpace: 'pre-line', '& a': { color: theme.palette.secondary.contrastText}}}>
          <Typography component={'span'} color="secondary.contrastText" dangerouslySetInnerHTML={{__html:text.paragraphs[5].content}}></Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(31), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[6].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(21), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{whiteSpace: 'pre-line', fontStyle: 'italic', textAlign: 'left', [theme.breakpoints.down('md')]: {columnCount: 1}}}>
          <Typography variant="custom5">
            {text.paragraphs[7].content}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer
        container
        emphasis
        sx={{
            marginTop: theme.spacing(26),
            paddingTop: theme.spacing(20),
            paddingBottom: theme.spacing(20),
            textAlign: 'center',
          }}
      >
        <Grid item md={12} sx={{whiteSpace: 'pre-line'}}>
          <Typography color="secondary.contrastText">
              {text.paragraphs[8].content}
          </Typography>
        </Grid>
        <Grid item md={12} sx={{paddingTop: 16}}>
          <Hyperlink item alignItems={'center'} url={text.contributeLink.link} secondary>{text.contributeLink.label}</Hyperlink>
        </Grid>
      </SiteGridContainer>

    </Main>
  );
};
