import React from 'react';
import useApi from './useApi';

const useInit = () => {
  const [data, setData] = React.useState();

  const home = useApi('/api/home-page?populate=subheader.image,buttons,links', result => {
    return {
      ...result.data.attributes,
      title: result.data.attributes.title,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const glossary = useApi('/api/glossary-page?populate=subheader.image', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const contribute = useApi('/api/contribute-page?populate=subheader.image', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const about = useApi('/api/about-page?populate=subheader.image,paragraphs,contributeLink', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const learn = useApi('/api/learn-page?populate=subheader.image,buttons', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const learnLevels = useApi('/api/learn-level-page?populate=subheader.image,buttons', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  const learnThemes = useApi('/api/learn-theme-page?populate=subheader.image,buttons', result => {
    return {
      ...result.data.attributes,
      image: result.data.attributes.subheader.image.data.attributes.url,
    }
  });

  // const sheetColumnMap = useApi('/api/sheet-column?populate=categories,levels', result => {
  //   return {
  //     ...result.data.attributes,
  //   }
  // });

  React.useEffect(()=>{
    if(home && glossary && contribute && about && learn && learnLevels && learnThemes){
      setData({ home, glossary, contribute, about, learn, learnLevels, learnThemes});
    }
  }, [home, glossary, contribute, about, learn, learnLevels, learnThemes])

  return data;
};


export default useInit;
