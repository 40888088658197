
import * as React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import CheckedIcon from './icons/CheckedIcon';

function Checkbox(props) {
  return (
    <MuiCheckbox {...props} checkedIcon={<CheckedIcon checked={true} />} icon={<CheckedIcon checked={false} />}/>
  );
}

export default Checkbox;
