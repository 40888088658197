import React from 'react';

export default () => {
  return (
    <svg width="37" height="21" viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.5" x2="37" y2="0.5" stroke="black"/>
      <line y1="20.5" x2="37" y2="20.5" stroke="black"/>
      <line x1="18" y1="10.5" x2="37" y2="10.5" stroke="black"/>
    </svg>
  )
}
