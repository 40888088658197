import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from './icons/DownloadIcon';
import GlossaryTabPanel from './GlossaryTabPanel';

function GlossaryPdfPanel(props) {
  const { value, index, printUrl, ...other } = props;

  return (
    <GlossaryTabPanel value={value} index={index}>
      <Box sx={{textAlign: 'center'}}>
        <Button component={'a'} target={'_blank'} href={printUrl} sx={{py: 0, px: 1}} variant="text"><DownloadIcon /><Typography variant={'body1'} sx={{mx: 4, lineHeight: '34px'}}>Download results as a pdf file.</Typography></Button>
      </Box>
    </GlossaryTabPanel>
  );
}

GlossaryPdfPanel.propTypes = {
  onClick: PropTypes.func,
};

export default GlossaryPdfPanel;
