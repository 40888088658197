import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import useApi from './hooks/useApi';
import Main from './Main';
import HeadImage from './components/HeadImage';
import SiteGridContainer from './components/SiteGridContainer';
import Hyperlink from './components/Hyperlink';
import { CustomButton } from './components/CustomButton';

export default ({data}) => {
  const theme = useTheme();
  const text = data;

  return (
    <Main>
      <HeadImage relativePath={text.image} />
      <SiteGridContainer sx={{marginTop: theme.spacing(11), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(10) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h3">{text.subheader.title}</Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer sx={{marginTop: theme.spacing(19), [theme.breakpoints.down('md')]: { marginTop: theme.spacing(12) }}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h4">
            {text.subheader.subtitle}
          </Typography>
        </Grid>
      </SiteGridContainer>
      <SiteGridContainer justifyContent="center" sx={{marginTop: 10, marginBottom: 25, [theme.breakpoints.down('md')]: { marginTop: 10, marginBottom: 18 }}}>
        <Grid item xs={6} sx={{paddingRight: theme.spacing(3)}}>
          <CustomButton
            link={text.buttons[0].link}
            sx={{width: '100%', minHeight: '120px', [theme.breakpoints.down('md')]: { minHeight: '120px'}}}
          >
            {text.buttons[0].label}
          </CustomButton>
        </Grid>
        <Grid item xs={6} sx={{paddingLeft: theme.spacing(3)}}>
          <CustomButton
            link={text.buttons[1].link}
            sx={{width: '100%', minHeight: '120px', [theme.breakpoints.down('md')]: { minHeight: '120px'}}}
          >
            {text.buttons[1].label}
          </CustomButton>
        </Grid>
      </SiteGridContainer>

    </Main>
  );
};
