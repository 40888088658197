import React from 'react';

export default ({value}) => {
  switch(value && value.toLowerCase().trim()){
    case 'accepted':
      return (
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 10.125L8.47619 15L19 2" stroke="#FCFCFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    case 'reclaimed':
      return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.94545 20H14.0764C14.0764 20 20 19.7354 20 13.4354C20 7.13543 14.0764 6.875 14.0764 6.875H2M2 6.875L7.59636 2M2 6.875L7.59636 11.375" stroke="#FCFCFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    case 'problematic/offensive':
      return (
        <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 2L5.70588 12.2941H12.7059L2 23M2 23V17.6471M2 23H6.94118" stroke="#FCFCFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    case 'disputed':
      return (
        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M2.45557 6.18519C3.95557 6.18526 3.95557 6.18602 3.95556 6.18676L3.95556 6.18818L3.95555 6.19073L3.95553 6.19477L3.95548 6.19864C3.95548 6.19895 3.95548 6.19862 3.95551 6.19766L3.95573 6.19027C3.95618 6.17776 3.95732 6.15206 3.96012 6.1151C3.96575 6.04067 3.97784 5.92387 4.00334 5.77944C4.05553 5.48388 4.1572 5.11073 4.346 4.75423C4.53079 4.40529 4.78915 4.08919 5.16081 3.85525C5.52714 3.62466 6.08968 3.41925 6.97371 3.41925C8.89287 3.41925 9.66732 4.01121 10.0079 4.43596C10.4002 4.92508 10.4939 5.53798 10.4939 5.9354C10.4939 6.82998 10.2699 7.32951 10.0015 7.69404C9.69424 8.11148 9.28283 8.43008 8.64418 8.92466L8.56505 8.98595C7.9356 9.4737 7.11924 10.1193 6.49575 11.0908C5.84914 12.0983 5.47371 13.3451 5.47371 14.9717C5.47371 15.8002 6.14529 16.4717 6.97371 16.4717C7.80214 16.4717 8.47371 15.8002 8.47371 14.9717C8.47371 13.8373 8.72581 13.1703 9.0205 12.7112C9.3383 12.216 9.77699 11.8421 10.4026 11.3573C10.4517 11.3192 10.5028 11.28 10.5554 11.2395C11.1137 10.8101 11.8495 10.2441 12.4175 9.47253C13.0905 8.55836 13.4939 7.42602 13.4939 5.9354C13.4939 5.18675 13.3367 3.79158 12.3483 2.55913C11.3084 1.26233 9.57275 0.41925 6.97371 0.41925C5.59877 0.41925 4.46706 0.747084 3.56268 1.31636C2.66362 1.88228 2.07482 2.63267 1.69482 3.35021C1.31883 4.06019 1.13808 4.75353 1.04904 5.25783C1.00393 5.51333 0.980702 5.7298 0.968677 5.88867C0.962645 5.96836 0.959374 6.03431 0.957607 6.08429C0.956723 6.1093 0.956213 6.13038 0.955924 6.14726L0.955636 6.16942L0.955581 6.17809L0.955569 6.18181L0.955566 6.18351C0.955566 6.18432 0.955565 6.18512 2.45557 6.18519ZM6.9738 22C7.80557 22 8.47986 21.3257 8.47986 20.4939C8.47986 19.6622 7.80557 18.9879 6.9738 18.9879C6.14203 18.9879 5.46775 19.6622 5.46775 20.4939C5.46775 21.3257 6.14203 22 6.9738 22Z" fill="#FCFCFB"/>
        </svg>
      );
    case 'reclaimed/offensive':
      return (
        <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.91902 2.55655C8.50254 1.96851 8.49887 1.01877 7.91082 0.435249C7.32278 -0.148269 6.37304 -0.144599 5.78952 0.443447L1.32416 4.94345C0.743833 5.52828 0.743833 6.47172 1.32416 7.05655L5.78952 11.5566C6.37304 12.1446 7.32278 12.1483 7.91082 11.5648C8.49887 10.9812 8.50254 10.0315 7.91902 9.44345L5.99054 7.5H14.426C15.2544 7.5 15.926 6.82843 15.926 6C15.926 5.17157 15.2544 4.5 14.426 4.5H5.99054L7.91902 2.55655ZM19.081 16.4434C18.4975 17.0315 18.5012 17.9812 19.0892 18.5648C19.6773 19.1483 20.627 19.1446 21.2105 18.5566L25.6759 14.0566C26.2562 13.4717 26.2562 12.5283 25.6759 11.9434L21.2105 7.44345C20.627 6.8554 19.6773 6.85173 19.0892 7.43525C18.5012 8.01877 18.4975 8.96851 19.081 9.55655L21.0095 11.5L12.5741 11.5C11.7457 11.5 11.0741 12.1716 11.0741 13C11.0741 13.8284 11.7457 14.5 12.5741 14.5L21.0095 14.5L19.081 16.4434Z" fill="white"/>
        </svg>
      )
  }
}
