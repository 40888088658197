import React from 'react';
import useQuery from './hooks/useQuery';
import LearnLevel from './LearnLevel';
import TermsGroup from './TermsGroup';
import useApi from './hooks/useApi';
import axiosInstance from './axios';

export default ({ data }) => {
  const query = useQuery();
  const store = useApi('/api/sheet-column?populate=levels', result => {
    return {
      ...result.data.attributes,
    }
  }, { levels: [] }, true);
  const filters = useApi('/api/google-sheets/filters', result => { return result.data }, [], true);

  const termGroup = 'maturity';

  const map = React.useMemo(() => {
    const instance = new Map(store.levels.map(item => [item.key, item.value]));
    const levels = filters.find(item => item.type === termGroup);
    if(levels){
      levels.labels.forEach(item => {
        if(!instance.has(item)){
          instance.set(item, item);
        }
      });
    }
    return instance;
  }, [store, filters])


  const value = React.useMemo(()=> {
    return query.get('value');
    // const parsed = parseInt(query.get('value'));
    // if(!Number.isNaN(parsed)){
    //   return parsed;
    // }
  }, [query]);


  const buttons = React.useMemo(() => {
    return [...map.keys()].map(item => {
      return {
        label: map.get(item),
        link: `/learn/level?value=${encodeURIComponent(item)}`,
      }
    })
  }, [map])

  if(value){
    const title = map.get(value) ? `${map.get(value)} terms` : '';

    return (
      <TermsGroup data={{...data, subheader: { ...data.subheader, title }}} group={termGroup} label={value}/>
    );
  } else {
    return (
      <LearnLevel data={{...data, buttons}} />
    );
  }
};
